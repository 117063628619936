.test-question-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.test-question-header {
  text-align: center;
  margin-bottom: 1rem;
}

.test-question-progress {
  margin-top: 0.5rem;
  height: 1rem;
  border-radius: 10px;
}

.test-question-images-with-scale {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.vertical-scale-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  align-self: stretch;
}

.vertical-scale-bar p {
  margin: 0;
  font-size: 0.9rem;
}

.scale-bar {
  flex: 1;
  width: 15px;
  background: linear-gradient(black, #f8f9fa);
  position: relative;
  border-radius: 12px;
  margin: 0.5rem 0;
}

.scale-label {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 50px);
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  color: white;
}

.test-question-images {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.test-question-image-wrapper {
  width: 48%;
  border-radius: 15px;
  overflow: hidden;

  figure {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.test-question-slider {
  margin-bottom: 1rem;
}

.test-question-slider label {
  display: block;
  margin-bottom: 0.5rem;
}

#suvmean-slider {
  width: 100%;
}

.test-question-slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.test-question-prompt {
  margin-bottom: 1rem;
  text-align: center;
}

.test-question-answers {
  margin-bottom: 1rem;
}

.minimalist-list-item {
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.minimalist-list-item:hover {
  background-color: #f0f0f0;
}

.test-question-feedback {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.test-question-next-button {
  margin-top: 1rem;
}

.minimalist-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
}

.minimalist-button:hover {
  background-color: #2980b9;
  color: white;
}

.list-group-item-success {
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.list-group-item-danger {
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.minimalist-range-slider {
  appearance: none;
  width: 100%;
  height: 5px;
  background: #e0e0e0;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease;
}

.minimalist-range-slider:hover {
  background: #d0d0d0;
}

.minimalist-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.minimalist-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.minimalist-range-slider::-webkit-slider-thumb:hover {
  background: #2980b9;
}

.minimalist-range-slider::-moz-range-thumb:hover {
  background: #2980b9;
}
