/* General Container Styling */
.terms-container {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
}

/* Card Styling for Sections */
.terms-card {
    background-color: white;
    border-radius: 16px;  /* Soft rounded corners */
    border: none;
    padding: 1.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);  /* Subtle shadow for clean depth */
    transition: box-shadow 0.2s ease-in-out;
}

.terms-card:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);  /* Slightly more prominent shadow on hover */
}

/* Headings */
.terms-card h3, .terms-card h4, .terms-card h5 {
    font-weight: 600;  /* Clean, modern font weight */
    color: #002372;  /* Consistent with brand or minimalist blue */
}

.terms-card p, .terms-card ul {
    color: #6c757d;  /* Muted text for body */
    line-height: 1.6;  /* Easier readability */
    font-size: 1rem;
}

/* List Styling */
.terms-card ul {
    list-style-type: none;
    padding-left: 1rem;
}

.terms-card ul li {
    position: relative;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
}

.terms-card ul li:before {
    content: '•';  /* Custom bullet point */
    position: absolute;
    left: 0;
    color: #002372;
}

/* Responsive Design */
@media (max-width: 768px) {
    .terms-container {
        padding: 1rem;  /* Reduced padding for mobile devices */
    }

    .terms-card {
        padding: 1rem;
    }

    .terms-card h3, .terms-card h4, .terms-card h5 {
        font-size: 1.25rem;
    }

    .terms-card p, .terms-card ul {
        font-size: 0.95rem;
    }
}
