.test-results-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.test-results-header h5 {
  margin-bottom: 0.5rem;
}

.test-results-header h4 {
  margin-bottom: 1rem;
}

.test-results-content p {
  margin-bottom: 1rem;
}

.test-results-actions {
  margin-top: 2rem;
}

.minimalist-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
}

.minimalist-button:hover {
  background-color: #2980b9;
  color: white;
}
