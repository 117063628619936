[data-amplify-authenticator] {
  --amplify-components-tabs-item-active-border-color: rgba(133, 193, 233, 1);
  --amplify-components-tabs-item-active-color: rgba(133, 193, 233, 1);
  --amplify-components-tabs-item-focus-color: rgba(133, 193, 233, 0.75);
  --amplify-components-tabs-item-hover-color: rgba(133, 193, 233, 0.75);
  --amplify-components-button-link-active-color: rgba(133, 193, 233, 1);
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  [data-amplify-router] {
    border: none;
  }

  .amplify-button:not([aria-checked]) {
    border-radius: 24px;
  }

  .amplify-button--primary {
    background-color: white; /* White background */
    border: 2px solid #003fe2; /* Solid border with #003fe2 color */
    color: #003fe2; /* Text color matches the border */
    font-weight: 500;
  }

  .amplify-button--primary:hover {
    background-color: #003fe2; /* Solid background color on hover */
    color: white; /* White text on hover */
    transition: background 0.15s, color 0.15s;
  }

  .amplify-button--primary:disabled {
    background: #a2a2a2;
    color: #fff;
    border: none;
  }

  .amplify-tabs-item[data-state='active'] {
    color: #003fe2;
  }

  .amplify-tabs-item:hover {
    color: #003fe2;
  }

  .amplify-button--link {
    color: #003fe2;
    text-decoration: none;
    transition: color 0.2s ease;
  }

  .amplify-button--link:hover {
    color: #001a4f;
  }
}

.amplify-checkbox {
  align-items: flex-start;
}

.amplify-checkbox__button {
  margin-top: 0.25rem;
  border-radius: 8px; /* Rounded corners for checkboxes */
  border: 1px solid rgba(133, 193, 233, 1); /* Lighter border color */
}

/* General container and layout */
.background-dark {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amplify-container {
  padding: 2rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 600px;
  width: 100%;
}

.amplify-checkbox__label {
  font-size: 1rem;
  color: black;
}

.amplify-checkbox__icon--checked {
  background-color: #002372 !important;
}

.amplify-checkbox__button {
  margin-right: 0.5rem;
  border-radius: 8px; /* Rounded checkboxes */
  border: 1px solid rgba(133, 193, 233, 1);
}

/* Input field styles */
.amplify-input {
  border-radius: 10px;
  border: 1px solid rgba(133, 193, 233, 1);
  padding: 0.75rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.amplify-input:focus {
  border-color: rgba(52, 152, 219, 1);
  box-shadow: 0px 0px 5px rgba(52, 152, 219, 0.5);
}

.amplify-field__show-password {
  height: calc(100% - 1rem) !important;
  width: auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

