/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: Urbanist, sans-serif;
  font-weight: 400;
  background-color: #f8f9fa;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.jumbotron-image-margin {
  margin-left: -8.33333333%;
}

/* md */
@media (min-width: 768px) {
  .offset-md-neg-2 {
    margin-left: -16.66666667%;
  }

  .offset-md-neg-1 {
    margin-left: -8.33333333%;
  }
}

/* lg */
@media (min-width: 992px) {
  .offset-lg-neg-1 {
    margin-left: -8.33333333%;
  }
}

/* xl */
@media (min-width: 1200px) {
  .offset-xl-0 {
    margin-left: 0;
  }
}

.bg-off-black {
  background: rgba(24, 24, 24, 1) !important;
}

.text-off-black {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-black {
  color: black;
}

.hidden {
  display: none;
}

.background-light {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-dark {
  background-color: #080046;
  border-radius: 36px;
  overflow: hidden;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 370px;
}

.subtitle-paragraph {
  text-align: justify;
  font-size: 18px;
}

.header-div {
  width: 200px;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(135deg, rgb(144, 202, 249), rgb(66, 165, 245));
  position: relative;
}

.header-div.left {
  margin-right: 50px;
  background: linear-gradient(135deg, rgb(144, 202, 249) 0%, rgb(66, 165, 245) 100%);
  margin-top: 50px;
}

.header-div.right {
  background: linear-gradient(135deg, rgb(66, 165, 245) 0%, rgb(0, 122, 255) 100%);
  margin-bottom: 50px;
}

.text-section {
  padding-left: 50px;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
  }
  .text-section {
    padding-left: 0;
  }
}

.btn-secondary {
  background-color: white; /* White background */
  border: 2px solid #002372; /* Solid border with #002372 color */
  color: #002372; /* Text color matches the border */
  font-weight: 500;
}

.btn-secondary:hover {
  background-color: #002372; /* Solid background color on hover */
  color: white; /* White text on hover */
  transition: background 0.15s, color 0.15s;
}

.btn-blue {
  background-color: white; /* White background */
  border: 2px solid #003fe2; /* Solid border with #003fe2 color */
  color: #003fe2; /* Text color matches the border */
  font-weight: 500;
}

.btn-blue:hover {
  background-color: #003fe2; /* Solid background color on hover */
  color: white; /* White text on hover */
  transition: background 0.15s, color 0.15s;
}


.btn-outline-blue {
  background: white;
  color: #002372;
  border-color: #002372;
}

.btn-outline-blue:hover {
  filter: brightness(80%);
  background: white;
  color: #002372;
  border-color: #002372;
  transition: filter 0.15s;
}

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
}

.test-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 250px;
}

.test-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
}

.test-card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.test-title {
  font-size: 1.5rem;
  color: #002372;
}

.test-description {
  font-size: 1rem;
  color: #6c757d;
  align-self: stretch;
  flex: 1;
}

.btn-test-start {
  background-color: white; /* White background */
  border: 2px solid #003fe2; /* Solid border with #003fe2 color */
  color: #003fe2; /* Text color matches the border */
  border-radius: 16px;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.btn-test-start:hover {
  background-color: #003fe2; /* Solid background color on hover */
  color: white; /* White text on hover */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}


.btn-test-retake {
  background-color: white; /* White background */
  border: 2px solid #002372; /* Solid border with #002372 color */
  color: #002372; /* Text color matches the border */
  border-radius: 16px;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.btn-test-retake:hover {
  background-color: #002372; /* Solid background color on hover */
  color: white; /* White text on hover */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}


.navbar {
  border-radius: 0 0 36px 36px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  background-color: #f8f9fa;
}


/* Responsive Breakpoints */
@media (max-width: 768px) {
  .test-card {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

/* General container styling */
.test-page-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Content container with rounded edges and shadow */
.test-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

/* Test header and question styles */
.test-header {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  color: #002372;
  margin-bottom: 1.5rem;
}

/* ListGroup for answers */
.list-group-item {
  border-radius: 12px !important;
  transition: background-color 0.3s, transform 0.3s;
}

.list-group-item:hover {
  transform: translateY(-5px);
  background-color: #f1f3f5;
}

/* Consistent button style */
.btn-outline-blue {
  background-color: white;
  color: #002372;
  border-color: #002372;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.btn-outline-blue:hover {
  background-color: #002372;
  color: white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.footer-container {
  margin-top: 12px;
  border-radius: 36px 36px 0 0;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  background-color: #f8f9fa;
}

.fullscreen-background {
  background: transparent;
  min-height: calc(100vh - 68px - 78px);
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
}

.amplify-button--primary,
.btn,
button[role='tab'] {
  border-radius: 50px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

/* Container for the Radiology Footer */
.radiology-footer-container {
  background-color: transparent;
  margin: 2rem 1rem;
  width: 100%;
}

/* Footer content layout */
.radiology-footer-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

/* Radiology logo styling */
.radiology-logo {
  height: 40px;
  width: auto;
  min-width: 160px;
}

/* Vertical rule for separation */
.vr {
  border-left: 1px solid #ddd;
  height: 40px;
}

/* Link styles */
.radiology-footer-link {
  color: #002372;
  text-decoration: none;
  font-weight: 500;
}

.radiology-footer-link:hover {
  color: rgb(41, 128, 185);
  text-decoration: underline;
}

/* Text styling */
.text-muted {
  color: #6c757d !important;
  font-size: 0.875rem;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .radiology-footer-content {
    text-align: center;
  }

  .vr {
    display: none;
  }
}

.test-page-container {
  min-height: 100vh;
}

.test-page-container > * {
  width: 100%;
}

.progress-bar {
  background: linear-gradient(90deg, #3498db, #2980b9);
}

.minimalist-range-slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
}

.minimalist-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
}

.minimalist-range-slider {
  appearance: none;
  height: 5px;
  background: #e0e0e0;
  border-radius: 5px;
  outline: none;
}

.minimalist-range-slider:focus {
  outline: none;
}

.minimalist-list-item:hover {
  background-color: #f0f0f0;
}

.minimalist-list-item.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.btn:hover {
  border-color: transparent !important;
}

.background-circles-recip {
  position: absolute;
  left: -150px;
  bottom: -100px;
  z-index: -100;
  width: 400px !important;
  opacity: 0.5;
}

body.test-page,
body.login-page {
  background-color: #080046;

  .background-circles {
    opacity: 1;
  }
}

.background-circles {
  position: absolute;
  left: -10vw;
  bottom: 0;
  z-index: -100;
  width: 50vw !important;
  opacity: 0.6;
}

.background-circles-human {
  width: 100%;
  z-index: 1;
  opacity: 0.6;
}

.educational-page-header {
  background-color: #080046;
  border-radius: 36px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}