.RECIPOnlineWaiver {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

@media (max-width: 768px) {
  .RECIPOnlineWaiver {
    padding: 8px;
  }
}

.waiver-card {
  border-radius: 16px;
  border: none;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.RECIPOnlineWaiver h6 {
  font-size: 16px;
  color: #002372;
  margin-bottom: 1rem;
}

.RECIPOnlineWaiver p {
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}

.RECIPOnlineWaiver ol {
  padding-left: 1rem;
}

.RECIPOnlineWaiver ol li {
  margin-bottom: 0.5rem;
}

.RECIPOnlineWaiver img {
  max-height: 40pt;
}

.text-md-right {
  text-align: right;
}

.shadow-sm {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
