/* Modal container and content styling */
.RECIPOnlineStudyFlyerModal > .modal-dialog > .modal-content {
  background-color: #ffffff; /* Light background */
  border-radius: 24px; /* Larger rounded corners */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  color: #002372; /* Text color aligned with blue palette */
  padding: 2rem;
  transition: background-color 0.3s ease;
}

.RECIPOnlineStudyFlyerModal--content {
  padding: 1.6rem !important;
  gap: 1rem !important; /* Increase gap for better spacing */
}

/* Image in modal */
.RECIPOnlineStudyFlyerModal--image > img {
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Shadow for image */
}

/* Buttons */
.RECIPOnlineStudyFlyerModal--button {
  padding: 12px 24px; /* Same as amplify button */
  border-radius: 12px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

/* Secondary button (No) */
.RECIPOnlineStudyFlyerModal--button.btn-outline-light {
  background-color: transparent;
  color: #002372;
  border: 2px solid rgba(133, 193, 233, 1); /* Light blue border */
  border-radius: 12px;
}

.RECIPOnlineStudyFlyerModal--button.btn-outline-light:hover {
  background-color: rgba(133, 193, 233, 0.2); /* Subtle hover effect */
  color: #002372;
  border-color: rgba(133, 193, 233, 1);
}

/* Close button */
.RECIPOnlineStudyFlyerModal--close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  filter: invert(1) grayscale(100%) brightness(200%);
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
}

/* Text styling with gradient */
.RECIPOnlineStudyFlyerModal--text-yellow {
  background: linear-gradient(45deg, #002372 0%, #0056a1 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive tweaks for medium and large devices */
@media (min-width: 576px) {
  .RECIPOnlineStudyFlyerModal--content-buttons > div:last-child {
    padding-top: initial;
  }
}

@media (min-width: 992px) {
  .RECIPOnlineStudyFlyerModal .modal-xl {
    --bs-modal-width: 982px;
  }
}

@media (min-width: 1200px) {
  .RECIPOnlineStudyFlyerModal .modal-xl {
    --bs-modal-width: 1140px;
  }

  .RECIPOnlineStudyFlyerModal--image {
    padding: 0;
  }

  .RECIPOnlineStudyFlyerModal--content {
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 1.6rem;
  }
}

.btn-close {
  filter: unset;
}
